<template>
    <div
        id="theme-customizer"
        :draggable="showSmallVideo ? true : false"
        :resizable="showSmallVideo ? true : false"
        :class="showSmallVideo ? 'drag-el' : ''"
        :style="showSmallVideo ? videoPosition : ''"
        @dragstart="isDragging = showSmallVideo ? true : false"
        @dragend="isDragging = showSmallVideo ? false : true"
        v-if="!p2pActive"
    >
        <!-- Open Customizer Button -->
        <vs-button v-show="pauseSmallBtn" @click.stop="showSidePanel" color="dark" type="filled" class="customizer-btn" icon-pack="feather" icon="icon-video" />
        <!-- Customizer Content -->
        <!-- <vs-sidebar
      ref="mysidebar"
      click-not-close
      hidden-background
      position-right
      v-model="active"
      class="items-no-padding"
    > -->
        <div :class="showSmallVideo ? '' : 'absolute sm:w-2/5 w-full h-full'" :style="showSmallVideo ? 'background: transparent;' : 'background: #000; z-index: 52000; right: 0;'" v-show="active">
            <div class="flex items-center justify-between cd-form-group pt-4 px-4" v-if="!bgCoverActive && !showSmallVideo">
                <div class="flex">
                    <!-- 縮小視訊視窗 按鈕 -->
                    <vs-button class="cd-button-group" icon-pack="feather" icon="icon-minimize-2" color="#000" @click.stop="minimizeVideo" />
                    <!-- 暫時隱藏 按鈕 -->
                    <vs-button class="cd-button-group" icon-pack="feather" icon="icon-minus" color="#000" @click.stop="hideSidePanel" />
                </div>
                <div>
                    <vs-button class="px-4 py-2 text-white" v-if="pInfo" @click="openInviteFamilyPopup">邀請民眾家屬加入</vs-button>
                </div>
                <!-- 截圖按鈕 按鈕 -->
                <!-- <vs-button icon-pack="feather" icon="icon-crop" v-if="isDev" @click.stop="screenshot"  /> -->
            </div>
            <div class="flex">
                <!-- 接電話的頁面 -->
                <div class="cover" id="cover" v-if="bgCoverActive">
                    <vs-row class="caller-info-grid">
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                            <div class="text-center">
                                <!-- 來電者的合作夥伴 -->
                                <p class="caller" v-show="callerPartnerEnterprise.partner_name != ''">{{ callerPartnerEnterprise.partner_name }}</p>
                                <!-- 來電者的服務單位 -->
                                <p class="caller" v-show="callerPartnerEnterprise.enterprise_name != ''">{{ callerPartnerEnterprise.enterprise_name }}</p>
                                <!-- 來電者的姓名 -->
                                <p class="caller">{{ callerName }}</p>
                            </div>
                        </vs-col>
                    </vs-row>
                    <vs-row>
                        <vs-col vs-type="flex" vs-justify="space-around">
                            <!-- 接電話 按鈕 -->
                            <vs-button size="60" type="flat" radius @click.stop="answer">
                                <img width="60" height="60" src="@/assets/images/pickup.png" alt="pickup" @click.stop="answer" />
                            </vs-button>
                            <!-- 掛電話 按鈕 -->
                            <vs-button size="60" type="flat" radius @click.stop="hangup">
                                <img width="60" height="60" src="@/assets/images/hangup.png" alt="hangup" @click.stop="hangup" />
                            </vs-button>
                        </vs-col>
                    </vs-row>
                </div>
                <!-- 電話接起來的頁面 -->
                <div v-else>
                    <!-- 上方拖拉工具欄 -->
                    <div class="iframe-nav-bar" v-if="showSmallVideo"></div>
                    <!-- 視訊遮罩 -->
                    <div class="iframe-mask" @click="showSidePanel" v-if="showSmallVideo">
                        <feather-icon icon="Maximize2Icon" class="icon" svgClasses="h-8 w-8"></feather-icon>
                    </div>
                    <div :class="showSmallVideo ? 'small-iframe' : ''">
                        <!-- <iframe
            frameborder="0"
            :class="showSmallVideo?'smallVideoView':'videoView'"
            :width="showSmallVideo?'250px':'100%'"
            :height="showSmallVideo?'150px':'100%'"
            id="p2pIframe"
            src="https://r.curdr.tw"
            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;" /> -->
                        <iframe
                            ref="videoView"
                            :src="videopath"
                            allow="microphone; camera; display-capture"
                            frameborder="0"
                            id="videoView"
                            :class="showSmallVideo ? 'smallVideoView' : 'videoView'"
                            :width="showSmallVideo ? '250px' : '100%'"
                            :height="showSmallVideo ? '150px' : '100%'"
                            v-if="videopath"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- 視訊拖拉位置 border-2 border-dashed -->
        <div v-if="isDragging">
            <div class="drop-place" v-for="(item, index) in dropArray" :key="index" :style="item + 'height: ' + (windowHeight - 65) / 3 + 'px;'" @drop.prevent="onDrop(index)" @dragenter.prevent @dragover.prevent></div>
        </div>
        <!-- 選擇邀請對象視窗 -->
        <vs-popup title="選擇邀請對象" :active.sync="inviteFamilyActive" @close="closeInviteFamilyPopup">
            <vs-row class="px-6" v-if="pInfo">
                <vs-col class="flex justify-between items-center cd-form-group">
                    <div class="whitespace-no-wrap truncate w-48">
                        <span class="mr-1 text-lg" style="color: #2d9b32" v-if="pInfo.token != null">●</span>
                        <span class="mr-1 text-lg" style="color: #626262" v-if="pInfo.token == null">●</span>
                        <span>{{ pInfo.name }}</span>
                    </div>
                    <div class="flex items-center text-gray whitespace-no-wrap" v-if="pInfo.inviteFlag == true">
                        <feather-icon class="mx-1" icon="CheckIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>已邀請</span>
                    </div>
                    <div class="flex items-center text-gray whitespace-no-wrap" v-else-if="!pInfo.token && partnerInfo.sms_invite == 0">
                        <span>離線</span>
                    </div>
                    <div :id="'inviteBtn' + pInfo.pid" class="flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="inviteAndNotifyFamily(pInfo.pid, pInfo.name)" v-else>
                        <feather-icon class="mx-1" icon="LinkIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>邀請</span>
                    </div>
                </vs-col>
                <vs-col v-if="pInfo.careGiver">
                    <div class="flex justify-between items-center" v-for="(item, index) in pInfo.careGiver" :key="index">
                        <div class="whitespace-no-wrap truncate w-48">
                            <span class="mr-1 text-lg" style="color: #2d9b32" v-if="item.token != null">●</span>
                            <span class="mr-1 text-lg" style="color: #626262" v-if="item.token == null">●</span>
                            <span>{{ item.relationship }}</span>
                        </div>
                        <div class="whitespace-no-wrap truncate w-full">
                            <span>{{ item.name }}</span>
                        </div>
                        <div class="flex items-center text-gray whitespace-no-wrap" v-if="item.inviteFlag == true">
                            <feather-icon class="mx-1" icon="CheckIcon" svgClasses="w-5 h-5"></feather-icon>
                            <span>已邀請</span>
                        </div>
                        <div class="flex items-center text-gray whitespace-no-wrap" v-else-if="!item.token && partnerInfo.sms_invite == 0">
                            <span>離線</span>
                        </div>
                        <div class="flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="inviteAndNotifyFamily(item.pid, pInfo.name, index)" v-else>
                            <feather-icon class="mx-1" icon="LinkIcon" svgClasses="w-5 h-5"></feather-icon>
                            <span>邀請</span>
                        </div>
                    </div>
                </vs-col>
            </vs-row>
        </vs-popup>
        <!-- <vs-popup :title="$t('PleaseSelect')" :active.sync="popupprescreenshot">
          <vs-col vs-type="flex" vs-justify="space-around">
            是否將此照片作為健保申報?
          </vs-col>
          <vs-col vs-type="flex" vs-justify="space-around">
            <avatar :action="api_url+'/api/uploadprofile/P'"/>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="space-around">
            <vs-button type="flat" @click.stop="openLoadingContained(callPayload, 1)">儲存</vs-button>
            <vs-button type="flat" @click.stop="openLoadingContained(callPayload, 0)">取消</vs-button>
          </vs-col>
        </vs-popup> -->
    </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import axios from 'axios'
import FeatherIcon from '../../../components/FeatherIcon.vue'
import Avatar from '@/components/Avatar'
import { getPatientInfoByPid, sendNotifyAndVideoLink } from '@/api/user'

export default {
    props: {
        footerType: { type: String, required: true },
        hideScrollToTop: { type: Boolean, required: true },
        navbarType: { type: String, required: true },
        navbarColor: { type: String, required: true, default: '#fff' },
        routerTransition: { type: String, required: true },
    },
    components: {
        Avatar,
    },
    data() {
        return {
            timestamp: '',
            deviceToken: localStorage.getItem('device_token'),
            sound: '',
            iframeWin: null,
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.6,
            },
            popupprescreenshot: false,
            // output: null,
            api_url: process.env.VUE_APP_API_URL_HIS,
            videoPosition: 'bottom: 15px; right: 15px;',
            isDragging: false,
            positionArray: [
                'top: 80px; left: 15px;',
                'top: 80px; right: 15px;',
                'bottom: 15px; left: 15px;',
                'bottom: 15px; right: 15px;',
                'top: 80px; left: 50%; margin-left: -125px;',
                'bottom: 15px; left: 50%; margin-left: -125px;',
                'top: 50%; left: 15px; margin-top: -55px;',
                'top: 50%; right: 15px; margin-top: -55px;',
                'top: 50%; left: 50%; margin-top: -55px; margin-left: -125px;',
            ],
            dropArray: [
                'top: 65px; left: 0px;',
                'top: 65px; right: 0px;',
                'bottom: 0px; left: 0px;',
                'bottom: 0px; right: 0px;',
                'top: 65px; left: 33.3%;',
                'bottom: 0px; left: 33.3%;',
                'top: ' + (window.innerHeight + 130) / 3 + 'px; left: 0px;',
                'top: ' + (window.innerHeight + 130) / 3 + 'px; right: 0px;',
                'top: ' + (window.innerHeight + 130) / 3 + 'px; left: 33.3%;',
            ],
            pInfo: null,
            inviteFamilyActive: false,
        }
    },
    watch: {
        layoutType(val) {
            // Reset unsupported options
            if (val === 'horizontal') {
                this.themeMode === 'semi-dark' ? (this.themeMode = 'light') : null
                this.navbarType === 'hidden' ? (this.navbarTypeLocal = 'floating') : null
                this.$emit('updateNavbarColor', '#fff')
            }
        },
        'activePatientInfo.pid'(val) {
            if (!val) return
            const payload = {
                spid: val,
            }
            getPatientInfoByPid(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.pInfo = res.data.data
                }
            })
        },
    },
    mounted() {
        // var _self = this
        // p2pIframe.addEventListener('load', function() {
        //   _self.sendLocalID(this.myP2PID)
        // });
        window.addEventListener('message', this.handleMessage)
        // this.iframeWin = this.$refs.iframe.contentWindow
    },
    computed: {
        isDev() {
            return this.$store.getters.isDev
        },
        pauseSmallBtn: {
            get() {
                return this.$store.getters.pauseSmallBtn
            },
            set(val) {
                this.$store.commit('CALLIN_MASK', val)
            },
        },
        callerName() {
            return this.$store.getters.callerName
        },
        callerPartnerEnterprise() {
            return this.$store.getters.callerPartnerEnterprise
        },
        bgCoverActive() {
            return this.$store.getters.callInMask
        },
        thisDevice() {
            return this.$store.getters.device
        },
        active: {
            get() {
                return this.$store.getters.showRightPanel
            },
            set() {
                this.$store.commit('CALLIN_MASK', false)
                this.$store.commit('TOGGLE_RIGHT_PANEL', true)
            },
        },
        videopath: {
            get() {
                return this.$store.getters.videoPath
            },
            set() {
                return ''
            },
        },
        footerTypeLocal: {
            get() {
                return this.footerType
            },
            set(val) {
                this.$emit('updateFooter', val)
            },
        },
        hideScrollToTopLocal: {
            get() {
                return this.hideScrollToTop
            },
            set(val) {
                this.$emit('toggleHideScrollToTop', val)
            },
        },
        navbarColorOptionClasses() {
            return (color) => {
                let classes = {}
                if (color == this.navbarColorLocal) classes['shadow-outline'] = true
                if (this.navbarTypeLocal == 'static') classes['cursor-not-allowed'] = true
                return classes
            }
        },
        navbarColorLocal: {
            get() {
                return this.navbarColor
            },
            set(val) {
                if (this.navbarType == 'static') return
                this.$emit('updateNavbarColor', val)
            },
        },
        navbarTypeLocal: {
            get() {
                return this.navbarType
            },
            set(val) {
                this.$emit('updateNavbar', val)
            },
        },
        layoutType: {
            get() {
                return this.$store.state.mainLayoutType
            },
            set(val) {
                this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', val)
            },
        },
        primaryColor: {
            get() {
                return this.$store.state.themePrimaryColor
            },
            set(val) {
                this.$store.commit('UPDATE_PRIMARY_COLOR', val)
            },
        },
        reduced_sidebar: {
            get() {
                return this.$store.state.reduceButton
            },
            set(val) {
                this.$store.commit('TOGGLE_REDUCE_BUTTON', val)
            },
        },
        routerTransitionLocal: {
            get() {
                return this.routerTransition
            },
            set(val) {
                this.$emit('updateRouterTransition', val)
            },
        },
        themeMode: {
            get() {
                return this.$store.state.theme
            },
            set(val) {
                this.$store.dispatch('updateTheme', val)
            },
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        windowHeight() {
            return window.innerHeight
        },
        showSmallVideo: {
            get() {
                return this.$store.getters.showSmallVideo
            },
            set(val) {
                this.$store.commit('SET_SHOW_SMALL_VIDEO', val)
            },
        },
        activePatientInfo() {
            return this.$store.getters.activePatientInfo
        },
        dname() {
            return this.$store.getters.name
        },
        title() {
            return this.$store.state.title
        },
        partnerid() {
            return this.$store.getters.partner
        },
        qid() {
            return this.$store.getters.qid
        },
        partnerInfo() {
            return JSON.parse(localStorage.getItem('partner_info'))
        },
        // 新版p2p視訊的開關，為了不跟舊的視訊衝突拿出來設定
        p2pActive() {
            return this.$store.state.videoActive
        },
    },
    destroyed() {
        // 移除監聽！
        window.removeEventListener('message', this.handleMessage)
    },
    methods: {
        handleMessage(event) {
            const data = event.data
            switch (data.cmd) {
                case 'rtcClose':
                    this.videopath = ''
                    this.hangup()
                    break
            }
        },
        // 掛電話
        hangup() {
            this.pauseSmallBtn = false
            // 所有登入醫生的裝置停止響鈴
            axios.post(process.env.VUE_APP_API_URL + '/app/restful_api/call/stopCall', { token: this.deviceToken, is_doctor: true })
            this.closeSidePanel()
        },
        // 接電話
        answer() {
            sound.stop()
            this.$store.commit('CALLIN_MASK', false)
            this.$store.dispatch('updateDoctorStatus', 2000)
            // 所有登入醫生的裝置停止響鈴
            axios.post(process.env.VUE_APP_API_URL + '/app/restful_api/call/stopCall', { token: this.deviceToken, is_doctor: true })
        },
        // 開啟sidebar
        showSidePanel() {
            if (this.videopath != '') {
                this.$store.commit('SET_PAUSE_SMALL_BTN', false)
                this.$store.dispatch('toggleRightPanel', true)
                this.$store.commit('SET_SHOW_SMALL_VIDEO', false)
            } else {
                alert(this.$t('NoVideo'))
            }
        },
        // 隱藏sidebar
        hideSidePanel() {
            this.$store.commit('SET_PAUSE_SMALL_BTN', true)
            this.$store.dispatch('toggleRightPanel', false)
            this.$store.commit('SET_SHOW_SMALL_VIDEO', false)
        },
        // 縮小視訊視窗
        minimizeVideo() {
            this.$store.commit('SET_SHOW_SMALL_VIDEO', true)
        },
        // 螢幕截圖 (目前沒有使用)
        async screenshot() {
            this.popupprescreenshot = true

            // var iframe = document.getElementById('videoView')
            // console.log("iframe",this.$refs.videoView)
            // const el = iframe.contentWindow || iframe.contentDocument.parentWindow
            // console.log("el",el)
            // const iframeBody = el.document.body
            // console.log("el",iframeBody)
            // // add option type to get the image version
            // // if not provided the promise will return
            // // the canvas.
            // const options = {
            //   backgroundColor: 'white',
            //   // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
            //   useCORS: true,
            //   // 允许跨域（图片跨域相关）
            //   allowTaint: true,
            //   // 是否在渲染前测试图片
            //   taintTest: true,
            //   type: 'dataURL'
            // }
            // this.output = await this.$html2canvas(iframeBody, options)
        },
        // 關閉通話視窗
        closeSidePanel() {
            sound.stop()
            this.$store.commit('SET_SHOW_SMALL_VIDEO', false)
            this.$store.commit('SET_PAUSE_SMALL_BTN', false)
            this.$store.commit('SET_CALLER_NAME', '')
            this.$store.dispatch('setVideoPath', '')
            this.$store.dispatch('toggleRightPanel', false)
            this.$store.commit('SET_CALLING', false) // 通話狀態
            this.$store.commit('SET_SYNC_STATUS', false) // 醫療影像同步狀態
            this.$store.commit('SET_SYNC_SWITCH', false) // 醫療影像同步狀態
            this.notify('gray', '已結束通話', '')
        },
        // 視窗通知
        notify(color, title, text) {
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                position: 'top-center',
            })
        },
        // 視窗拖拉後 放下時觸發
        onDrop(index) {
            this.videoPosition = this.positionArray[index]
        },
        // 開啟 選擇邀請對象視窗
        openInviteFamilyPopup() {
            this.inviteFamilyActive = true
        },
        // 關閉 選擇邀請對象視窗
        closeInviteFamilyPopup() {
            this.inviteFamilyActive = false
        },
        // 通知並邀請民眾與民眾家屬加入視訊
        inviteAndNotifyFamily(pid, pname, index = null) {
            const payload = {
                qid: this.qid,
                spid: pid,
                partner_id: this.partnerid,
                notify_title: this.$t('stream.notify_title', { dname: this.dname + this.$common.getLangDB(this.title) }),
                notify_content: this.$t('stream.notify_content', { dname: this.dname + this.$common.getLangDB(this.title), pname: pname }),
                msg: this.$t('stream.msg', { dname: this.dname + this.$common.getLangDB(this.title), pname: pname }),
            }
            sendNotifyAndVideoLink(payload).then((res) => {
                if (res.data.status == 'OK') {
                    if (index == null) {
                        this.pInfo.inviteFlag = true
                    } else {
                        this.pInfo.careGiver[index].inviteFlag = true
                    }
                    this.$store.dispatch('notify', { color: 'grey', title: '邀請成功' })
                    this.$forceUpdate()
                }
            })
        },
    },
    components: {
        VuePerfectScrollbar,
        FeatherIcon,
    },
}
</script>
<style lang="scss">
#theme-customizer {
    .vs-sidebar {
        position: fixed;
        z-index: 52000;
        width: 40%;
        max-width: 800vw;
        box-shadow:
            0 15px 30px 0 rgba(0, 0, 0, 0.11),
            0 5px 15px 0 rgba(0, 0, 0, 0.08);
    }
}

.customizer-btn {
    position: fixed;
    top: 50%;
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 50000;
}

.scroll-area--customizer {
    height: calc(100% - 5rem);
}
@media only screen and (max-device-width: 768px) {
    #theme-customizer {
        .vs-sidebar {
            position: fixed;
            z-index: 52000;
            width: 100%;
            box-shadow:
                0 15px 30px 0 rgba(0, 0, 0, 0.11),
                0 5px 15px 0 rgba(0, 0, 0, 0.08);
        }
    }
}
@media only screen and (max-device-width: 480px) {
    #theme-customizer {
        .vs-sidebar {
            position: fixed;
            z-index: 52000;
            width: 100%;
            box-shadow:
                0 15px 30px 0 rgba(0, 0, 0, 0.11),
                0 5px 15px 0 rgba(0, 0, 0, 0.08);
        }
        .videoView {
            position: absolute;
            display: flex;
            background: #fff;
            border: none;
            height: 100vh;
            width: 100vw;
            z-index: 201;
        }
    }
}
@media only screen and (max-device-width: 976px) {
    #theme-customizer {
        .vs-sidebar {
            position: fixed;
            z-index: 52000;
            width: 100%;
            max-width: 100vw;
            // @apply shadow-lg;
            box-shadow:
                0 15px 30px 0 rgba(0, 0, 0, 0.11),
                0 5px 15px 0 rgba(0, 0, 0, 0.08);
        }
        .videoView {
            position: absolute;
            display: flex;
            background: #fff;
            border: none;
            height: 92vh;
            width: 100vw;
            z-index: 201;
        }
    }
}
</style>
<style>
.cover {
    width: 100vw;
    height: 100vh;
    background-color: rgba(16, 22, 58, 0.5);
    z-index: 199;
}
.videoView {
    position: static;
    display: flex;
    background: #fff;
    border: none;
    height: 90vh;
    width: 40vw;
    z-index: 201;
}
.smallVideoView {
    position: static;
    display: flex;
    background: black;
    border: none;
    height: 150px;
    width: 250px;
    z-index: 90000;
}
.caller {
    font-size: 24px;
    color: white;
    font-weight: bold;
}
.caller-info-grid {
    height: 65vh;
}
.cover .vs-icon {
    font-size: 3rem !important;
}
.cover .includeIconOnly {
    width: 6vw !important;
    height: 9vh !important;
}
.drag-el {
    position: fixed;
    width: 250px;
    height: 170px;
    background-color: black;
    opacity: 1;
    z-index: 90002;
    cursor: pointer;
}
/* .drag-el:hover {
  opacity: 0.3;
  color: #fff;
} */
.drop-place {
    position: fixed;
    width: 33.3%;
    z-index: 90002;
}
.iframe-nav-bar {
    position: fixed;
    width: 250px;
    height: 20px;
    background-color: black;
    z-index: 90001;
    cursor: pointer;
}
.iframe-mask {
    position: fixed;
    margin-top: 20px;
    width: 250px;
    height: 150px;
    background-color: black;
    opacity: 0;
    z-index: 90001;
    cursor: pointer;
}
.iframe-mask:hover {
    opacity: 0.5;
    color: #fff;
}
.iframe-mask .icon {
    opacity: 1;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
}
.small-iframe {
    position: fixed;
    z-index: 90000;
    margin-top: 20px;
}
</style>
