<template>
    <vs-popup :title="title" :active.sync="active" @close="close" class="err_dialog" popup-style="width: 800px; max-width: 90%;">
        <div class="sm:px-8 px-2 mb-base whitespace-no-wrap">
            <div class="py-2">
                <div v-html="content" style="white-space: pre-line;">  </div>
            </div>
            <div class="grid gap-4 place-items-center">
                <vs-button color="dark" type="border" class="rounded whitespace-no-wrap" @click="close" style="width: 120px;">{{ $t('membersMgmt.addmember.close_btn') }}</vs-button>
            </div>
        </div>
    </vs-popup>
</template>
<script>

export default {
    components: {  },
    data() {
        return {
            active: false
        }
    },
    props: {
        openPopup: {
            type: Boolean,
            default: false,
            required: true,
        },
        content: {
            type: String,
            default: '',
            required: true,
        },
        title: {
            type: String,
            default: '',
            required: true,
        },
    },
    watch: {
        openPopup(n) {
            this.active = n
        }
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        close() {
            this.$emit('closeErrDialogPopup')
        }
    },
}
</script>
