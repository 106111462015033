<template>
    <!-- 提示視窗 - 您將發送會議連結，請病人進入診間。病人進入診間後，您也會收到提醒通知。 -->
    <vs-popup :title="$t('popup.alert')" :active.sync="popupActive" @close="close">
        <p class="cd-form-group">{{ $t('reservation.smsText') }}</p>
        <vs-button class="mr-2" type="border" @click="close">{{ $t('popup.cancel') }}</vs-button>
        <vs-button class="ml-2" type="filled" @click="sendlink">{{ $t('popup.confirm') }}</vs-button>
    </vs-popup>
</template>
<script>
// import { inviteVideo, invite } from '@/api/user'
export default {
    props: {
        invitePopupActive: {
            type: Boolean,
            required: true,
            default: false,
        },
        qid: {
            type: Number,
            required: false,
            default: 0,
        },
        room_id: {
            type: Number,
            required: false,
            default: 0,
        },
        pmid: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    computed: {
        popupActive: {
            get() {
                return this.invitePopupActive
            },
            set(v) {
                return v
            },
        },
    },
    methods: {
        // 發送簡訊連結
        sendlink() {
            this.$vs.loading()
            const payload = {
                mid: this.pmid,
                room_id: this.room_id,
                is_doctor: 0,
            }
            if (this.qid > 0) payload.queue_id = this.qid
            this.$store.dispatch('invite', payload).then((room_id) => {
                if (this.qid > 0) {
                    const invitePayload = {
                        queue_id: this.qid,
                        room_id: room_id,
                    }
                    this.$bus.$emit('sendInvite', invitePayload)
                }
                this.$emit('closePopup')
                this.$vs.loading.close()
            })
        },
        // sendlink() {
        //     this.$vs.loading()
        //     const payload = {
        //         qid: this.qid,
        //         type: 'video',
        //     }
        //     // 發送視訊邀請(含通知)
        //     inviteVideo(payload)
        //         .then((res) => {
        //             this.$emit('closePopup')
        //             this.$store.dispatch('notify', {
        //                 color: 'success',
        //                 title: this.$t('message.invite_success'),
        //             })
        //             // this.notify('success', this.$t('message.invite_success'), '')
        //             this.$store.dispatch('updateDoctorStatus', 2000)
        //             this.$vs.loading.close()
        //         })
        //         .catch((e) => {
        //             console.log('send sms error ', e)
        //             this.$store.dispatch('notify', {
        //                 color: 'danger',
        //                 title: this.$t('message.invite_error'),
        //             })
        //             // this.notify('danger', this.$t('message.invite_error'), '')
        //         })
        //     // 將該筆掛號加入定期檢查房間狀態的行列
        //     var mcudata = _.cloneDeep(this.$store.state.mcudata)
        //     if (!_.find(mcudata, { mid: this.pmid, qid: this.qid })) {
        //         mcudata.push({ mid: this.pmid, qid: this.qid })
        //     }
        //     this.$store.commit('SET_MCUDATA', mcudata)
        // },
        // 視窗通知
        // notify(color, title, text) {
        //     this.$vs.notify({
        //         color: color,
        //         title: title,
        //         text: text,
        //         time: 8000,
        //         position: 'top-center',
        //     })
        // },
        // 關閉popup
        close() {
            this.$emit('closePopup')
        },
    },
}
</script>
